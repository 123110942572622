<template>
  <NavigationLayout :padding="true">
    <SplitLayout
      :route-param="routeParam"
      :query-param-map="queryParamMap"
      :route-component="components.routeComponent">
      <Table
        :data="pages"
        :config="config"
        @row-selected="rowSelected">
        <template slot="controls">
          <vk-icon-button
            :href="createNewObjectLink"
            ratio="0.85"
            icon="plus"
          />
        </template>
      </Table>
    </SplitLayout>
  </NavigationLayout>
</template>

<script>
  import NavigationLayout from '../../components/layout/NavigationLayout'
  import SplitLayout from '../../components/layout/SplitLayout'
  import SplitViewMixin from '../../mixins/split-view'
  import Table from '../../components/global/Table'
  import Page from '../../views/web/Page'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        pages: 'web/pages',
      }),
    },
    data() {
      return {
        config: null,
      }
    },
    created() {
      this.setup('pages', 'pageId', Page)
      this.config = this.createTableConfig('nav.pages', 'web.no_pages_found')

      // Loading Pages
      this.loading = true
      this.$store.dispatch('web/loadPages', {
        appId: this.$route.params.appId
      }).finally(() => this.loading = false)
    },
    mixins: [
      SplitViewMixin
    ],
    components: {
      Table,
      SplitLayout,
      NavigationLayout
    }
  }
</script>