<template>
  <form
    class="uk-form-stacked full-height-form"
    @submit.prevent="$emit('submit')">
    <vk-card class="full-height-form">
      <HeaderControl :title="title">
        <slot name="header-controls" />
      </HeaderControl>
      <div class="scrollable-content">
        <slot />
      </div>
      <div slot="footer" v-if="$slots['footer-controls']">
        <slot name="footer-controls" />
      </div>
    </vk-card>
  </form>
</template>

<script>
  import HeaderControl from './HeaderControl'

  export default {
    props: [
      'title'
    ],
    components: {
      HeaderControl,
    }
  }
</script>

<style lang="scss" scoped>
  .full-height-form {
    max-height: 100%;
  }

  .scrollable-content {
    width: 100%;
    overflow-y: auto;
    padding-right: 16px;
    height: calc(100% - 180px);
  }
</style>