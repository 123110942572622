<template>
  <div class="comment-wrapper">
    <div class="avatar">
      <img src='images/avatars/avatar_1.svg' />
    </div>
    <div class="content-wrapper">
      <div class="meta">
        <div class="name">{{ creator ? creator : $t('app.removed_user') }}</div>
        <div class="date">{{ created_at | datetime }} {{ $t('generic.clock') }}</div>
      </div>
      <div
        class="comment"
        v-html="content"
      />
      <div class="controls">
        <ConfirmModal
          @confirm="deleteComment"
          :title="$t('projects.confirm_delete_comment_title')"
          :message="$t('projects.confirm_delete_comment_message')">
          <div class="control">{{ $t('generic.delete') }}</div>
        </ConfirmModal>
      </div>
    </div>
  </div>
</template>

<script>
  import ConfirmModal from "@/components/global/modals/ConfirmModal";
  export default {
    components: {ConfirmModal},
    props: [
      'id',
      'content',
      'creator',
      'created_at'
    ],
    methods: {
      deleteComment() {
        this.loading = true
        this.$store.dispatch('project/deleteComment', {
          appId: this.$route.params.appId,
          id: this.id,
        }).then(res => {
          if (res && res.success) {
            this.$toasted.success(this.$t('projects.comment_deleted'))
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .comment-wrapper {
    width: 100%;
    min-height: 72px;
    display: flex;
    margin-top: 16px;
    box-sizing: border-box;
    background: $color-primary;
    justify-content: space-between;

    .avatar {
      flex-grow: 0;
      display: flex;
      flex-shrink: 0;
      padding: 8px 16px;
      align-items: center;
      justify-content: center;

      img {
        max-width: 48px;
        max-height: 48px;
      }
    }

    .content-wrapper {
      width: 100%;
      padding: 8px;

      .meta {
        display: flex;
        font-size: 12px;
        margin-bottom: 4px;
        justify-content: space-between;

        .name {
          font-weight: 600;
        }
      }
    }

    .controls {
      display: flex;
      justify-content: flex-end;

      .control {
        cursor: pointer;
        font-size: 12px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

</style>