<template>
  <div :class="{
    'icon-button-wrapper': true,
    'icon-button-wrapper--selected': selected
    }"
    @click.stop="$emit('click', $event)">
    <div class="icon">
      <slot />
    </div>
    <div
      v-if="title"
      class="title">
      {{ title }}
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'title',
      'selected'
    ]
  }
</script>

<style lang="scss" scoped>
  .icon-button-wrapper {
    display: flex;
    cursor: pointer;
    border-radius: 25px;
    background: $color-secondary;

    &:hover,
    &.icon-button-wrapper--selected {
      background: darken($color-secondary, 7.5%);
    }

    .icon {
      margin: 2px;
      width: 28px;
      height: 28px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background: $color-primary;
    }

    .title {
      display: flex;
      user-select: none;
      align-items: center;
      padding: 0 24px 0 12px;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }
</style>