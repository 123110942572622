<template>
  <Table
    :config="config"
    :data="foreignValues"
    @row-selected="selectRow">
    <template slot="controls">
      <IconDropdown
        icon="plus"
        @update="assign"
        position="bottom-left"
        :value="assignedPages"
        :values="transformedPages">
        <vk-icon-button
          :tooltip="$t('web.append_page')"
          @click.prevent.stop=""
          type="button"
          ratio="0.85"
          icon="plus"
        />

        <vk-button
          slot="button"
          type="primary"
          class="uk-width-1-1@s"
          @click.stop="$router.push(createQueryParamLink('new'))">
          {{ $t('web.new_page') }}
        </vk-button>
      </IconDropdown>
    </template>
  </Table>
</template>

<script>
  import VkIconButton from '../../../../components/global/vuikit/VkIconButton'
  import IconDropdown from '../../../../components/projects/IconDropdown'
  import QueryParamMixin from '../../../../mixins/query-param'
  import Table from '../../../global/Table'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        pages: 'web/pages'
      }),
      transformedPages() {
        return this.pages.map(page => {
          return {
            id: page.id,
            name: page.name
          }
        })
      },
      assignedPages() {
        if (this.$route.params.customerId) {
          return this.pages
            .filter(page => page.customer_id)
            .map(page => page.id)
        }

        if (this.$route.params.providerId) {
          return this.pages
            .filter(page => page.provider_id === this.$route.params.providerId)
            .map(page => page.id)
        }

        if (this.$route.params.domainId) {
          return this.pages
            .filter(page => page.domain_id === this.$route.params.domainId)
            .map(page => page.id)
        }

        if (this.$route.params.serverId) {
          return this.pages
          .filter(page => page.server_id === this.$route.params.serverId)
          .map(page => page.id)
        }
      }
    },
    data() {
      return {
        config: this.createTableConfig('web.no_pages_found', 'pages')
      }
    },
    methods: {
      assign($event) {
        let promise = null
        let payload = {
          reference_ids: $event,
          reference_type: 'page',
          appId: this.$route.params.appId,
        }

        if (this.$route.params.customerId) {
          payload.id = this.$route.params.customerId
          promise =this.$store.dispatch('customer/assignToCustomer', payload)
        }

        if (this.$route.params.providerId) {
          payload.id = this.$route.params.providerId
          promise = this.$store.dispatch('web/assignToProvider', payload)
        }

        if (this.$route.params.domainId) {
          payload.id = this.$route.params.domainId
          promise = this.$store.dispatch('web/assignToDomain', payload)
        }

        if (this.$route.params.serverId) {
          payload.id = this.$route.params.serverId
          promise = this.$store.dispatch('web/assignToServer', payload)
        }

        if (promise) {
          /** @var Promise */
          promise.then(() => this.$store.dispatch('web/loadPages', payload))
        }
      }
    },
    mixins: [
      QueryParamMixin
    ],
    components: {
      VkIconButton,
      IconDropdown,
      Table
    }
  }
</script>