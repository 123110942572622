<template>
  <Detail
    :title="title('projects.new_project')"
    @submit="submit">

    <!-- Controls -->
    <template slot="header-controls">
      <vk-icon-button
        v-vk-tooltip="tooltip('generic.save')"
        type="submit"
        icon="check"
      />
    </template>

    <!-- Content -->
    <vk-grid class="uk-margin-top">
      <labeled-input
        required
        v-model="model.name"
        class="uk-width-1-1@s">
        {{ $t('generic.name') }}
      </labeled-input>

      <labeled-input
        required
        type="textarea"
        v-model="model.comment"
        class="uk-width-1-1@s uk-margin-small-top">
        {{ $t('generic.note') }}
      </labeled-input>
    </vk-grid>
  </Detail>
</template>

<script>
import LabeledInput from '../../components/global/inputs/LabeledInput'
import VkIconButton from '../../components/global/vuikit/VkIconButton'
import Comments from '../../components/projects/Comments'
import Detail from '../../components/layout/details/Detail'
import DetailMixin from '../../mixins/detail'
import {mapGetters} from 'vuex'

export default {
  created() {
    this.setup(
      'projectId',
      'project/projectById',
      'projects.project_created')
  },
  methods: {
    submit() {
      return this.saveModel('project/saveProject').then(() => {
        if (this.isCreateMode) {
          this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/projects/' + this.model.id + '/view')
        }
      })
    },
  },
  mixins: [
    DetailMixin
  ],
  components: {
    Detail,
    Comments,
    LabeledInput,
    VkIconButton,
  }
}
</script>