<template>
  <NavigationLayout>
    <ProjectLayout :title="project.name">
      <!-- Controls -->
      <template slot="controls">
        <div class="controls-wrapper"></div>
        <div class="controls-wrapper">
          <Resources
            @update="assignResourcesToProject"
            :value="project.resources"
          />
        </div>
      </template>

      <!-- Tasks -->
      <TaskList
        slot="middle"
        :tasks="project.tasks"
      />

      <!-- Content -->
      <Task
        slot="right"
        v-if="$route.query && $route.query['taskId']"
      />
      <Customer
        slot="right"
        v-if="$route.query && $route.query['customerId']"
      />
      <Domain
        slot="right"
        v-if="$route.query && $route.query['domainId']"
      />
      <Page
        slot="right"
        v-if="$route.query && $route.query['pageId']"
      />
      <Provider
        slot="right"
        v-if="$route.query && $route.query['providerId']"
      />
      <Server
        slot="right"
        v-if="$route.query && $route.query['serverId']"
      />
    </ProjectLayout>
  </NavigationLayout>
</template>

<script>
  import NavigationLayout from '../../components/layout/NavigationLayout'
  import ProjectLayout from '../../components/layout/ProjectLayout'
  import IconDropdown from '../../components/projects/IconDropdown'
  import IconButton from '../../components/projects/IconButton'
  import Resources from '../../components/projects/Resources'
  import TaskList from '../../components/projects/TaskList'
  import Task from '../../views/projects/Task'
  import Customer from '../../views/customers/Customer'
  import Provider from '../../views/web/Provider'
  import Server from '../../views/web/Server'
  import Domain from '../../views/web/Domain'
  import Page from '../../views/web/Page'
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        projectById: 'project/projectById',
        customers: 'customer/customers',
        providers: 'web/providers',
        domains: 'web/domains',
        servers: 'web/servers',
        pages: 'web/pages',
        users: 'app/users'
      }),
      project() {
        return this.projectById(this.$route.params.projectId)
      }
    },
    methods: {
      assignResourcesToProject($event) {
        let resources = this.project.resources
        if (!resources) {
          resources = []
        }

        // Remove from Type (Server, Customer, Provider, ...)
        resources = resources.filter(item => item.type !== $event.type)
        $event.value.forEach(value => {
          resources.push({
            type: $event.type,
            value: value
          })
        })

        this.$store.dispatch('project/assignResourcesToProject', {
          appId: this.$route.params.appId,
          id: this.$route.params.projectId,
          resources
        }).then(res => {
          if (res && res.success) {
            this.$toasted.success(this.$t('projects.project_updated'))
          }
        })
      }
    },
    components: {
      Resources,
      Task,
      Page,
      Server,
      Domain,
      Provider,
      TaskList,
      Customer,
      IconButton,
      IconDropdown,
      ProjectLayout,
      NavigationLayout
    }
  }
</script>

<style lang="scss" scoped>
  .controls-wrapper {
    display: flex;
    align-items: center;

    .rounded {
      border-radius: 50%;
    }
  }
</style>