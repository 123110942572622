<template>
  <div class="tasks-wrapper">
    <div class="headline">{{ $t('nav.tasks' )}}</div>
    <div class="sections">
      <div
        class="section"
        v-for="section in status">
        <div class="section-headline">{{ $t('projects.task_status_' + section) }}</div>
        <div class="section-content">
          <TaskListEntry
            :key="index"
            :id="task.id"
            :name="task.name"
            @completeTask="completeTask"
            v-for="(task, index) in getTasksByStatus(section)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TaskListEntry from '../projects/TaskListEntry'

  export default {
    props: [
      'tasks'
    ],
    data() {
      return {
        status: [
          'open',
          'waiting',
          'in_progress',
          'waiting_for_response',
          'testing',
          'finished'
        ],
      }
    },
    methods: {
      getTasksByStatus(status) {
        if (this.tasks) {
          return this.tasks.filter(task => task.status === status)
        }
      },
      completeTask(id) {
        let completableTask = this.tasks.find(task => task.id === id)
        if (completableTask) {
          this.$store.dispatch('project/saveTask', {
            ...completableTask,
            status: 'finished',
            appId: this.$route.params.appId
          }).then(res => {
            if (res && res.success) {
              this.$toasted.success(this.$t('projects.task_completed'))
            }
          })
        }
      }
    },
    components: {
      TaskListEntry
    }
  }
</script>

<style lang="scss" scoped>
  .tasks-wrapper {
    padding: 24px;
    height: calc(100% - 48px);

    .headline {
      font-size: 20px;
      font-weight: 200;
    }

    .sections {
      width: 100%;
      overflow-y: auto;
      padding-right: 20px;
      height: calc(100% - 6px);

      .section {
        margin: 24px 0;

        .section-headline { font-weight: 200; }
        .section-content { margin-top: 8px; }
      }
    }
  }
</style>