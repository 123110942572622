<template>
  <div class="modal-wrapper">
    <div
      class="modal-button"
      @click.prevent="show = !show">
      <slot />
    </div>
    <vk-modal :show.sync="show">
      <vk-modal-title>{{ title }}</vk-modal-title>
      <p>{{ message }}</p>
      <p class="uk-text-right">
        <vk-button
          @click="show = false"
          class="uk-margin-small-right">
          {{ $t('generic.cancel') }}
        </vk-button>
        <vk-button
          type="primary"
          @click="confirm">
          {{ $t('generic.confirm') }}
        </vk-button>
      </p>
    </vk-modal>
  </div>
</template>

<script>
  export default {
    props: [
      'title',
      'message'
    ],
    data() {
      return {
        show: false,
      }
    },
    methods: {
      confirm() {
        this.show = false
        this.$emit('confirm')
      }
    }
  }
</script>