<template>
  <vk-card-title>
    <div class="header-control">
      <div class="header-title">{{ title }}</div>
      <div class="header-controls">
        <slot />
      </div>
    </div>
  </vk-card-title>
</template>

<script>
  export default {
    props: [ 'title' ]
  }
</script>

<style lang="scss" scoped>
  .header-control {
    display: flex;
    user-select: none;
    justify-content: space-between;

    .header-controls {
      display: flex;
    }
  }
</style>