import Vue from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './locales'
import Vuikit from 'vuikit'
import router from './router'
import VueToasted from 'vue-toasted'
import VuikitIcons from '@vuikit/icons'
import VueFilters from './helpers/filters'
import '@vuikit/theme'


Vue.use(Vuikit)
Vue.use(VueFilters)
Vue.use(VueToasted, {
  position: 'top-right',
  duration: 2000
})
Vue.use(VuikitIcons)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
