<template>
  <NavigationLayout :padding="true">
    <vk-grid class="uk-flex-center uk-text-center uk-margin-remove-left uk-margin-large-top">
      <vk-card padding="small" class="uk-width-1-2@m uk-padding-remove">
        <vk-card-title
          class="uk-margin-remove-bottom uk-text-left"
          slot="header">
          {{ $t('nav.settings') }}
        </vk-card-title>

        <vk-tabs class="uk-margin-top-small">
          <vk-tabs-item :title="$t('app.account')">
            <form
              @submit.prevent="submit"
              class="uk-form-stacked uk-text-left">
              <vk-grid class="uk-margin-top">
                <labeled-input
                  required
                  v-model="user.lastname"
                  class="uk-width-1-2@s">
                  {{ $t('generic.lastname') }}
                </labeled-input>
                <labeled-input
                  required
                  v-model="user.firstname"
                  class="uk-width-1-2@s">
                  {{ $t('generic.firstname') }}
                </labeled-input>

                <labeled-input
                  required
                  disabled
                  type="email"
                  v-model="user.email"
                  class="uk-width-1-1@s uk-margin-small">
                  {{ $t('generic.email') }}
                </labeled-input>

                <labeled-input
                  type="password"
                  v-model="user.password"
                  class="uk-width-1-2@s uk-margin-small">
                  {{ $t('generic.password') }}
                </labeled-input>

                <labeled-input
                  type="password"
                  v-model="user.password_confirm"
                  class="uk-width-1-2@s uk-margin-small">
                  {{ $t('generic.password_confirm') }}
                </labeled-input>
              </vk-grid>

              <div class="uk-flex uk-flex-between uk-margin-top">
                <vk-loadable-button
                  type="primary"
                  :loading="loading"
                  html-type="submit">
                  {{ $t('generic.save') }}
                </vk-loadable-button>
              </div>
            </form>
          </vk-tabs-item>
          <vk-tabs-item
            :title="$t('generic.users')">
            <User
              :id="user.id"
              :key="user.id"
              :email="user.email"
              :fullname="user.fullname"
              v-for="user in users"
            />
          </vk-tabs-item>

          <vk-tabs-item :title="$t('app.add_user')">
            <vk-grid class="uk-flex-center uk-text-center">
              <form
                @submit.prevent="createNewUser"
                class="uk-form-stacked uk-text-left">
                <vk-grid class="uk-margin-top">
                  <labeled-input
                    required
                    v-model="newUser.lastname"
                    class="uk-width-1-2@s">
                    {{ $t('generic.lastname') }}
                  </labeled-input>
                  <labeled-input
                    required
                    v-model="newUser.firstname"
                    class="uk-width-1-2@s">
                    {{ $t('generic.firstname') }}
                  </labeled-input>

                  <labeled-input
                    required
                    type="email"
                    v-model="newUser.email"
                    class="uk-width-1-1@s uk-margin-small">
                    {{ $t('generic.email') }}
                  </labeled-input>
                </vk-grid>
                <vk-loadable-button
                  type="primary"
                  html-type="submit"
                  :loading="loading"
                  class="uk-margin-small-top">
                  {{ $t('generic.save') }}
                </vk-loadable-button>
              </form>
            </vk-grid>
          </vk-tabs-item>
        </vk-tabs>
      </vk-card>
    </vk-grid>
  </NavigationLayout>
</template>

<script>
  import NavigationLayout from '../components/layout/NavigationLayout'
  import VkLoadableButton from '../components/global/vuikit/VkLoadableButton'
  import LabeledInput from '../components/global/inputs/LabeledInput'
  import User from '../components/settings/User'
  import Table from '../components/global/Table'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        users: 'app/users'
      })
    },
    data() {
      return {
        loading: false,
        addUser: false,
        newUser: {},
        user: {},
      }
    },
    created() {
      // Set existing fields
      this.user.email = this.$store.getters['auth/email']
      this.user.lastname = this.$store.getters['auth/lastname']
      this.user.firstname = this.$store.getters['auth/firstname']

      // Load users
      this.loading = true
      this.$store.dispatch('app/loadUsers', {
        appId: this.$route.params.appId
      }).finally(() => this.loading = false)
    },
    methods: {
      submit() {
        if (this.user.password !== this.user.password_confirm) {
          this.$toasted.error(this.$t('auth.password_confirm_wrong'))
          return
        }

        this.loading = true
        this.$store.dispatch('auth/update', this.user).then(res => {
          if (res && res.success) {
            this.$toasted.success(this.$t('app.update_successful'))
          }
        }).finally(() => this.loading = false)
      },
      createNewUser() {
        this.loading = true
        this.$store.dispatch('app/createUser', {
          appId: this.$route.params.appId,
          email: this.newUser.email,
          lastname: this.newUser.lastname,
          firstname: this.newUser.firstname,
        })
        .then((res) => {
          if (res && res.success) {
            this.$toasted.success(this.$t('app.account_created', { email: this.newUser.email }))
            this.newUser = {}
          }
        })
        .finally(() => this.loading = false)
      }
    },
    components: {
      NavigationLayout,
      VkLoadableButton,
      LabeledInput,
      Table,
      User
    }
  }
</script>