export default {
    app: {
        account: 'Konto',
        add_user: 'Benutzer hinzufügen',
        removed_user: 'Gelöschter Benutzer',
        validation_failed: 'Fülle die Felder ordnungsgemäß aus',
        account_created: 'Ein Benutzer für {email} wurde erfolgreich angelegt',
        account_deleted: 'Der Benutzer wurde erfolgreich gelöscht',
        update_successful: 'Deine Änderungen wurden erfolgreich gespeichert',
        cant_remove_owner: 'Du kannst den Eigentümer nicht löschen',
        cant_remove_foreign_app: 'Du kannst keinen Benutzer aus einem anderen System löschen',
        confirm_delete_user_title: 'Benutzer löschen?',
        confirm_delete_user_message: 'Möchtest du den Benutzer löschen?',
    },
    auth: {
        login: 'Anmelden',
        register: 'Registrieren',
        not_registered_yet: 'Ich habe noch keinen Account',
        registered_yet: 'Ich habe bereits einen Account?',
        accept_terms: 'Ich akzeptiere die Datenschutzerklärung',
        password_confirm_wrong: 'Das wiederholte Kennwort stimmt nicht mit dem Kennwort überein',
        signup_successful: 'Du hast dich erfolgreich registriert',
        user_already_exists: 'Diese E-Mail wurde bereits registriert',
        welcome: 'Willkommen zurück',
        user_not_found: 'Diese E-Mail ist bei uns nicht registriert',
        forgot: 'Passwort vergessen?',
        unauthenticated: 'Du wurdest ausgeloggt. Melde dich bitte erneut an',
        reset_password: 'Passwort zurücksetzen',
        reset_password_successful: 'Dein Passwort wurde erfolgreich zurückgesetzt.',
        reset_message: '',
    },
    generic: {
        confirm: 'Bestätigen',
        cancel: 'Abbrechen',
        clock: 'Uhr',
        general: 'Allgemein',
        save: 'Speichern',
        delete: 'Löschen',
        email: 'E-Mail',
        password: 'Kennwort',
        password_confirm: 'Kennwort wiederholen',
        firstname: 'Vorname',
        lastname: 'Nachname',
        search: 'Suche...',
        name: 'Name',
        domain: 'Domain',
        customer: 'Kunde',
        tag: 'Tag',
        url: 'URL',
        provider: 'Provider',
        server: 'Server',
        note: 'Notiz',
        phone: 'Telefon',
        project: 'Projekt',
        task: 'Aufgabe',
        status: 'Status',
        description: 'Beschreibung',
        comments: 'Kommentare',
        comment: 'Kommentar',
        users: 'Benutzer',
        select_all: 'Alle auswählen',
        resources: 'Resourcen',
    },
    nav: {
        dashboard: 'Dashboard',
        projects: 'Projekte',
        tasks: 'Aufgaben',
        customers: 'Kunden',
        domains: 'Domains',
        providers: 'Provider',
        servers: 'Server',
        pages: 'Seiten',
        settings: 'Einstellungen',
        logout: 'Abmelden'
    },
    web: {
        new_page: 'Neue Seite',
        new_domain: 'Neue Domain',
        new_server: 'Neuer Server',
        new_provider: 'Neuer Provider',
        no_pages_found: 'Keine Seiten gefunden',
        no_servers_found: 'Keine Server gefunden',
        no_domains_found: 'Keine Domains gefunden',
        no_providers_found: 'Keine Provider gefunden',
        servers: {
            hostname: 'Hostname',
            ipv4: 'IPv4',
            ipv6: 'IPv6',
        },
        pages: {
            online: 'online',
            offline: 'offline',
            unknown: 'unbekannt',
            pending: 'wird ermittelt',
            monitoring: 'Monitoring aktivieren',
            monitoring_sub: 'Benachrichtigungen werden gesendet an: {email}'
        },
        provider_created: 'Der Provider wurde erfolgreich angelegt',
        provider_updated: 'Der Provider wurde erfolgreich aktualisiert',
        provider_deleted: 'Der Provider wurde erfolgreich gelöscht',
        domain_created: 'Die Domain wurde erfolgreich angelegt',
        domain_updated: 'Die Domain wurde erfolgreich aktualisiert',
        domain_deleted: 'Die Domain wurde erfolgreich gelöscht',
        no_provider: 'Kein Provider',
        no_server: 'Kein Server',
        no_domain: 'Keine Domain',
        provider_not_found: 'Der Provider konnte nicht gefunden werden',
        domain_not_found: 'Die Domain konnte nicht gefunden werden',
        server_not_found: 'Der Server konnte nicht gefunden werden',
        server_created: 'Der Server wurde erfolgreich angelegt',
        server_updated: 'Der Server wurde erfolgreich aktualisiert',
        server_deleted: 'Der Server wurde erfolgreich gelöscht',
        page_created: 'Die Seite wurde erfolgreich angelegt',
        page_updated: 'Die Seite wurde erfolgreich aktualisiert',
        page_deleted: 'Die Seite wurde erfolgreich gelöscht',
        confirm_delete_provider_title: 'Provider löschen?',
        confirm_delete_provider_message: 'Möchtest du den Provider wirklich löschen?',
        confirm_delete_domain_title: 'Domain löschen?',
        confirm_delete_domain_message: 'Möchtest du den Domain wirklich löschen?',
        confirm_delete_server_title: 'Server löschen?',
        confirm_delete_server_message: 'Möchtest du den Server wirklich löschen?',
        confirm_delete_page_title: 'Website löschen?',
        confirm_delete_page_message: 'Möchtest du die Website wirklich löschen?',
        append_server: 'Server hinzufügen',
        append_page: 'Seite hinzufügen',
        append_domain: 'Domain hinzufügen'
    },
    customers: {
        new_customer: 'Neuer Kunde',
        no_customers_found: 'Keine Kunden gefunden.',
        customer_created: 'Der Kunde wurde erfolgreich angelegt',
        customer_updated: 'Der Kunde wurde erfolgreich aktualisiert',
        customer_deleted: 'Der Kunde wurde erfolgreich gelöscht',
        no_customer: 'Kein Kunde',
        not_found: 'Der Kunde konnte nicht gefunden werden',
        confirm_delete_customer_message: 'Möchtest du diesen Kunden löschen?',
        confirm_delete_customer_title: 'Kunde löschen?',
    },
    projects: {
        new_task: 'Neue Aufgabe',
        new_project: 'Neues Projekt',
        no_task_found: 'Keine Aufgabe gefunden',
        no_project_found: 'Keine Projekte gefunden.',
        comment: 'Kommentieren',
        task_created: 'Die Aufgabe wurde erfolgreich angelegt',
        task_updated: 'Die Aufgabe wurde erfolgreich aktualisiert',
        task_deleted: 'Die Aufgabe wurde erfolgreich gelöscht',
        project_created: 'Das Projekt wurde erfolgreich angelegt',
        project_updated: 'Das Projekt wurde erfolgreich aktualisiert',
        project_deleted: 'Das Projekt wurde erfolgreich gelöscht',
        comment_created: 'Der Kommentar wurde erfolgreich gespeichert',
        comment_deleted: 'Der Kommentar wurde erfolgreich gelöscht',
        task_completed: 'Die Aufgabe wurde erfolgreich abgeschlossen',
        task_status_open: 'Offen',
        task_status_waiting: 'Wartend',
        task_status_in_progress: 'In Bearbeitung',
        task_status_waiting_for_response: 'Warte auf Rückfrage',
        task_status_testing: 'Testing',
        task_status_finished: 'Abgeschlossen',
        no_project: 'Kein Projekt',
        delete_project: 'Projekt löschen',
        assigned: 'Zugeordnete',
        can_not_assigned_project_does_not_contain: 'Die Resource kann der Aufgabe nicht zugeordnet werden, da dem Projekt diese Resource nicht zugeordnet ist',
        confirm_delete_project_title: 'Projekt löschen?',
        confirm_delete_project_message: 'Möchtest du dieses Projekt wirklich löschen?',
        confirm_delete_task_title: 'Aufgabe löschen?',
        confirm_delete_task_message: 'Möchtest du diese Aufgabe wirklich löschen?',
        confirm_delete_comment_title: 'Kommentar löschen?',
        confirm_delete_comment_message: 'Möchtest du diesen Kommentar wirklich löschen?',
    },
    tooltips: {
        link_to_project: 'Mit Projekt verküpfen',
        create_project: 'Neues Projekt',
        create_task: 'Neue Aufgabe',
        create_customer: 'Neuer Kunde',
        create_provider: 'Neuer Provider',
        create_domain: 'Neue Domain',
        create_server: 'Neuer Server',
        create_page: 'Neue Seite',
    },
}