<template>
  <div class="navigation-layout">
    <div class="navigation">
      <div class="nav-top-wrapper">
        <!-- Logo -->
        <Sitetape class="uk-margin-large-top uk-margin-large-bottom uk-flex uk-flex-center" />

        <!-- Search -->
        <input
          @input="search"
          class="uk-input search-bar"
          :placeholder="$t('generic.search')"
        />

        <!-- Entries -->
        <vk-nav class="uk-margin-top">
          <vk-nav-item
            :key="index"
            class="nav-item"
            :icon="entry.icon"
            :href="entry.link"
            :title="$t(entry.label)"
            :active="isActive(entry.link)"
            v-for="(entry, index) in entries"
          />
        </vk-nav>
      </div>

      <hr/>
      <div class="nav-bottom-wrapper">
        <div class="avatar">
          <img src='https://avataaars.io/?avatarStyle=Circle&topType=WinterHat2&accessoriesType=Sunglasses&hatColor=
          Blue01&facialHairType=BeardMagestic&facialHairColor=BrownDark&clotheType=BlazerSweater&eyeType=Hearts&eyebrowType
          =Angry&mouthType=Serious&skinColor=Brown'
          />
          <div class="name">{{ $store.getters['auth/fullname'] }}</div>
        </div>
      </div>
    </div>
    <div
      :class="{
        'content': true,
        'content-padding': padding
      }">
      <slot />
    </div>
  </div>
</template>

<script>
  import Sitetape from '../global/Sitetape'

  export default {
    props: [
      'padding'
    ],
    data() {
      return {
        entries: [
          {
            icon: 'thumbnails',
            label: 'nav.dashboard',
            link: this.prepare(),
          },
          {
            icon: 'calendar',
            label: 'nav.projects',
            link: this.prepare('/projects'),
          },
          {
            icon: 'check',
            label: 'nav.tasks',
            link: this.prepare('/tasks'),
          },
          {
            icon: 'users',
            label: 'nav.customers',
            link: this.prepare('/customers'),
          },
          {
            icon: 'close',
            label: 'nav.providers',
            link: this.prepare('/providers'),
          },
          {
            icon: 'close',
            label: 'nav.domains',
            link: this.prepare('/domains'),
          },
          {
            icon: 'server',
            label: 'nav.servers',
            link: this.prepare('/servers'),
          },
          {
            icon: 'world',
            label: 'nav.pages',
            link: this.prepare('/pages'),
          },
          {
            icon: 'cog',
            label: 'nav.settings',
            link: this.prepare('/settings'),
          },
          {
            icon: 'sign-out',
            label: 'nav.logout',
            link: '#/' + this.$i18n.locale + '/auth/logout',
          },
        ]
      }
    },
    methods: {
      prepare(url) {
        return (this.$router.mode === 'hash' ? '#' : '')
            + '/' + this.$i18n.locale + '/' + this.$route.params.appId
            + (url ? url : '')
      },
      isActive(link) {
        return (this.$router.mode === 'hash' ? '#' : '') + this.$route.path === link
      },
      /**
       * Debounces the event and fires a search against the api
       * @param $event
       */
      search($event) {
        this.throttle(() => {
          let targetURL = '/' + this.$i18n.locale + '/' + this.$route.params.appId + '/search?keyword=' + $event.target.value
          if (targetURL !== this.$route.fullPath) {
            this.$router.push(targetURL)
          }
        }, 500)
      },
      throttle(func, limit) {
        if (!this.timeout) {
          this.timeout = setTimeout(func, limit)
        } else {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(func, limit)
        }
      },
    },
    components: {
      Sitetape
    }
  }
</script>

<style lang="scss" scoped>
  .navigation-layout {
    width: 100%;
    height: 100vh;
    display: flex;

    .navigation {
      width: 100%;
      z-index: 2;
      max-width: 256px;
      padding: 12px 20px;
      height: calc(100% - 24px);
      background: $color-secondary;
      box-shadow: 0 0 1.25rem rgba(0, 0, 0, .2);

      .nav-top-wrapper {
        width: 100%;
        overflow-y: auto;
        height: calc(100% - 110px);
      }

      .nav-bottom-wrapper {
        display: flex;

        .avatar {
          width: 100%;
          padding: 8px;
          display: flex;
          border-radius: 4px;
          background: $color-primary;

          img {
            max-width: 48px;
            max-height: 48px;
          }

          .name {
            color: $color-tertiary;
            width: 100%;
            display: flex;
            font-weight: bold;
            align-items: center;
            margin-left: 20px;
          }
        }
      }

      .nav-item {
        border-radius: 4px;
        padding: 4px 8px;
        margin: 4px 0;

        &:hover,
        &:hover > a > .uk-icon,
        &:hover > a > .uk-text-middle {
          background: $color-tertiary;
          color: $color-secondary;
        }
      }
    }
  }

  .uk-active {
    background: $color-tertiary;
  }

  .uk-nav-default > li.uk-active > a {
    color: $color-secondary;
  }

  .content {
    overflow-y: auto;
    width: 100%;
    height: 100%;

    &.content-padding {
      padding: 24px;
      width: calc(100% - 48px);
      height: calc(100% - 48px);
    }
  }

  .search-bar {
    border-radius: 8px;
  }
</style>