import APIFactory from '../../api/factory'

export default {
  namespaced: true,
  state: {
    projects: [],
    tasks: [],
  },
  mutations: {
    setTasks(state, tasks) {
      state.tasks = tasks
    },
    setProjects(state, projects) {
      state.projects = projects
    },
  },
  actions: {
    loadTasks({commit}, payload) {
      return APIFactory.findTasks(payload.appId).then(res => {
        if (res && res.success) {
          commit('setTasks', res.data)
        }
        return res
      })
    },
    loadProjects({commit}, payload) {
      return APIFactory.findProjects(payload.appId).then(res => {
        if (res && res.success) {
          commit('setProjects', res.data)
        }
        return res
      })
    },
    saveTask({commit, state, getters, dispatch}, payload) {
      return (!getters.taskById(payload.id)
        ? APIFactory.createTask(
          payload.appId,
          payload.name,
          payload.status,
          payload.project_id,
          payload.comment,
          payload.resources)
        : APIFactory.updateTask(
          payload.appId,
          payload.id,
          payload.name,
          payload.status,
          payload.project_id,
          payload.comment,
          payload.resources))
      .then((res) => {
        return dispatch('loadTasks', payload).then(() => res)
      })
    },
    saveComment({dispatch}, payload) {
      return APIFactory.comment(
        payload.appId,
        payload.reference_id,
        payload.reference_type,
        payload.content).then((res) => {
        dispatch('loadTasks', payload)
        return res
      })
    },
    saveProject({commit, getters, dispatch}, payload) {
      return (!getters.projectById(payload.id)
        ? APIFactory.createProject(payload.appId, payload.name, payload.comment)
        : APIFactory.updateProject(payload.appId, payload.name, payload.comment)).then(res => {
          return dispatch('loadProjects', payload).then(() => res)
      })
    },
    deleteProject({dispatch}, payload) {
      return APIFactory.deleteProject(payload.appId, payload.id).then(res => {
        dispatch('loadProjects', payload)
        return res
      })
    },
    deleteTask({dispatch}, payload) {
      return APIFactory.deleteTask(payload.appId, payload.id).then(res => {
        dispatch('loadTasks', payload)
        return res
      })
    },
    deleteComment({dispatch}, payload) {
      return APIFactory.deleteComment(payload.appId, payload.id).then(res => {
        dispatch('loadTasks', payload)
        return res
      })
    },
    assignResourcesToProject({dispatch}, payload) {
      return APIFactory.assignResources(payload.appId, payload.id, payload.resources).then(res => {
        dispatch('loadProjects', payload)
        return res
      })
    }
  },
  getters: {
    commentsByTaskId: (state) => (taskId) => {
      let task = state.tasks.find(task => task.id === taskId)
      if (task) {
        return task.comments || []
      }
    },
    tasks: (state) => state.tasks,
    taskById: (state) => (id) => state.tasks.find(task => task.id === id),
    projects: (state) => state.projects,
    projectById: (state) => (id) => state.projects.find(project => project.id === id)
  }
}