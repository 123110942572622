<template>
  <NavigationLayout :padding="true">
    <SplitLayout
      :route-param="routeParam"
      :query-param-map="queryParamMap"
      :route-component="components.routeComponent">
      <Table
        :data="servers"
        :config="config"
        @row-selected="rowSelected">
        <template slot="controls">
          <vk-icon-button
            :href="createNewObjectLink"
            ratio="0.85"
            icon="plus"
          />
        </template>
      </Table>
    </SplitLayout>
  </NavigationLayout>
</template>

<script>
import NavigationLayout from '../../components/layout/NavigationLayout'
import SplitLayout from '../../components/layout/SplitLayout'
import SplitViewMixin from '../../mixins/split-view'
import Server from '../../views/web/Server'
import Table from '../../components/global/Table'
import Page from '../../views/web/Page'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      servers: 'web/servers'
    })
  },
  data() {
    return {
      config: null,
    }
  },
  created() {
    this.setup('servers', 'serverId', Server, {
      pageId: Page
    })
    this.config = this.createTableConfig('nav.servers', 'web.no_servers_found')

    // Loading Servers
    this.loading = true
    this.$store.dispatch('web/loadServers', {
      appId: this.$route.params.appId
    }).finally(() => this.loading = false)
  },
  mixins: [
    SplitViewMixin
  ],
  components: {
    Table,
    SplitLayout,
    NavigationLayout
  }
}
</script>