<template>
  <div class="project-layout-wrapper">
    <div class="headline">
      <div>{{ title }}</div>
      <div class="uk-flex">
        <vk-icon-button
          @click="newTask"
          icon="check"
          type="button"
          class="uk-margin-small-right"
          :tooltip="$t('projects.new_task')"
        />
        <ConfirmModal
          @confirm="deleteProject"
          :title="$t('projects.confirm_delete_project_title')"
          :message="$t('projects.confirm_delete_project_message')">
          <vk-icon-button
            icon="trash"
            type="button"
            :tooltip="$t('projects.delete_project')"
          />
        </ConfirmModal>
      </div>
    </div>
    <div class="controls">
      <slot name="controls" />
    </div>
    <div class="content-wrapper">
      <div class="milestones">
        <FeatureTeaser />
      </div>
      <div class="middle">
        <slot name="middle" />
      </div>
      <div class="content">
        <slot
          name="right"
          v-if="$slots['right']"
        />
        <template v-else>
          <FeatureTeaser />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import ConfirmModal from '../../components/global/modals/ConfirmModal'
  import VkIconButton from '../../components/global/vuikit/VkIconButton'
  import FeatureTeaser from '../../components/projects/FeatureTeaser'

  export default {
    props: [
      'title'
    ],
    methods: {
      deleteProject() {
        this.$store.dispatch('project/deleteProject', {
          appId: this.$route.params.appId,
          id: this.$route.params.projectId
        }).then(res => {
          if (res && res.success) {
            this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/projects')
          }
        })
      },
      newTask() {
        this.$router.push({query: { taskId: 'new' }})
      }
    },
    components: {
      ConfirmModal,
      FeatureTeaser,
      VkIconButton
    }
  }
</script>

<style lang="scss" scoped>
  $headline-height: 32px;
  $controls-height: 32px;
  $headline-padding: 16px;
  $controls-padding: 8px;

  .project-layout-wrapper {
    width: 100%;
    height: 100%;

    .headline {
      display: flex;
      font-size: 20px;
      align-items: center;
      height: $headline-height;
      padding: $headline-padding;
      background: $color-secondary;
      justify-content: space-between;
      width: calc(100% - (2 * #{$headline-padding}));
    }

    .controls {
      z-index: 2;
      display: flex;
      height: $controls-height;
      background: $color-gray-02;
      justify-content: space-between;
      padding: $controls-padding $headline-padding;
      width: calc(100% - (2 * #{$headline-padding}));
    }

    .content-wrapper {
      width: 100%;
      display: flex;
      height: calc(100% - (#{$headline-height} + (2 * #{$headline-padding}) + #{$controls-height} + (2 * #{$controls-padding})));

      .milestones {
        padding: 24px;
        display: flex;
        flex-shrink: 0;
        flex-basis: 242px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: $color-secondary;
      }

      .middle {
        height: 100%;
        flex-shrink: 0;
        flex-basis: 520px;
        background: darken($color-gray-01, 2%);
      }

      .content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: white;
        flex-basis: calc(100% - 810px);
      }
    }
  }
</style>