<template>
  <Detail
    :title="title('customers.new_customer')"
    @submit="submit">

    <template slot="header-controls">
      <vk-icon-button
        icon="check"
        type="submit"
        :tooltip="tooltip('generic.save')"
      />
      <ConfirmModal
        @confirm="deleteCustomer"
        :title="$t('customers.confirm_delete_customer_title')"
        :message="$t('customers.confirm_delete_customer_message')">
        <vk-icon-button
          icon="trash"
          type="button"
          v-if="!isCreateMode"
          class="uk-margin-small-left"
          :tooltip="tooltip('generic.delete')"
        />
      </ConfirmModal>
    </template>

    <vk-tabs>
      <vk-tabs-item :title="$t('generic.general')">
        <vk-grid class="uk-margin-top">
          <labeled-input
            required
            v-model="model.name"
            class="uk-width-1-1@s">
            {{ $t('generic.name') }}
          </labeled-input>

          <labeled-input
            v-model="model.phone"
            class="uk-width-1-1@s uk-margin-small">
            {{ $t('generic.phone') }}
          </labeled-input>

          <labeled-input
            type="textarea"
            v-model="model.comment"
            class="uk-width-1-1@s uk-margin-small">
            {{ $t('generic.note') }}
          </labeled-input>
        </vk-grid>
      </vk-tabs-item>

      <!-- Domains -->
      <vk-tabs-item
        :icon="tabIcon"
        :disabled="isCreateMode"
        :title="$t('nav.domains')">
        <Domains
          query-param="domainId"
          parent-type="customer"
          :foreign-values="domainValues"
        />
      </vk-tabs-item>
      <!-- Seiten -->
      <vk-tabs-item
        :icon="tabIcon"
        :disabled="isCreateMode"
        :title="$t('nav.pages')">
        <Pages
          query-param="pageId"
          :foreign-values="pageValues"
        />
      </vk-tabs-item>
      <!-- Server -->
      <vk-tabs-item
        :icon="tabIcon"
        :disabled="isCreateMode"
        :title="$t('nav.servers')">
        <Servers
          query-param="serverId"
          :foreign-values="serverValues"
        />
      </vk-tabs-item>
    </vk-tabs>
  </Detail>
</template>

<script>
  import {mapGetters} from 'vuex'
  import DetailMixin from '../../mixins/detail'
  import Detail from '../../components/layout/details/Detail'
  import Pages from '../../components/layout/details/references/Pages'
  import VkIconButton from '../../components/global/vuikit/VkIconButton'
  import Servers from '../../components/layout/details/references/Servers'
  import Domains from '../../components/layout/details/references/Domains'
  import LabeledInput from '../../components/global/inputs/LabeledInput'
  import ConfirmModal from '../../components/global/modals/ConfirmModal'

  export default {
    computed: {
      ...mapGetters({
        servers: 'web/servers',
        domains: 'web/domains',
        pages: 'web/pages',
      }),
      pageValues() {
        return this.pages.filter(page => page.customer_id === this.$route.params[this.routeParam]
          || page.customer_id === this.$route.query[this.routeParam])
      },
      serverValues() {
        return this.servers.filter(server => server.customer_id === this.$route.params[this.routeParam]
          || server.customer_id === this.$route.query[this.routeParam])
      },
      domainValues() {
        return this.domains.filter(domain => domain.customer_id === this.$route.params[this.routeParam]
          || domain.customer_id === this.$route.query[this.routeParam])
      }
    },
    created() {
      this.setup(
        'customerId',
        'customer/customerById',
        'customers.customer_created',
        'customers.customer_updated',
        'customers.customer_deleted')

      // Loading referencing models
      this.loading = true
      let payload = { appId: this.$route.params.appId }
      Promise.all([
        this.$store.dispatch('web/loadServers', payload),
        this.$store.dispatch('web/loadDomains', payload),
        this.$store.dispatch('web/loadPages', payload)
      ]).finally(() => this.loading = false)
    },
    methods: {
      submit() {
        return this.saveModel('customer/saveCustomer', ).then((res) => {
          if (this.isCreateMode) {
            this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/customers/' + this.model.id)
          }
        })
      },
      deleteCustomer() {
        return this.deleteModel('customer/deleteCustomer').then((res) => {
          if (res && res.success) {
            this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/customers')
          }
        })
      }
    },
    mixins: [
      DetailMixin
    ],
    components: {
      ConfirmModal,
      VkIconButton,
      LabeledInput,
      Servers,
      Domains,
      Detail,
      Pages
    }
  }
</script>