<template>
  <Detail
    :title="title('projects.new_task')"
    @submit="submit">

    <!-- Controls -->
    <template slot="header-controls">
      <vk-icon-button
        v-vk-tooltip="tooltip('generic.save')"
        type="submit"
        icon="check"
      />
      <ConfirmModal
        @confirm="deleteTask"
        :title="$t('projects.confirm_delete_task_title')"
        :message="$t('projects.confirm_delete_task_message')">
        <vk-icon-button
          v-vk-tooltip="tooltip('generic.delete')"
          class="uk-margin-small-left"
          type="button"
          icon="trash"
        />
      </ConfirmModal>
    </template>

    <!-- Content -->
      <vk-grid class="uk-margin-top">
        <labeled-input
          required
          v-model="model.name"
          class="uk-width-1-1@s">
          {{ $t('generic.name') }}
        </labeled-input>

        <labeled-input
          type="select"
          :values="status"
          v-model="model.status"
          class="uk-width-1-2@s uk-margin-small">
          {{ $t('generic.status') }}
        </labeled-input>

        <labeled-input
          type="select"
          :values="projectValues"
          v-model="model.project_id"
          class="uk-width-1-2@s uk-margin-small">
          {{ $t('generic.project') }}
        </labeled-input>

        <labeled-input
          type="textarea"
          v-model="model.comment"
          class="uk-width-1-1@s uk-margin-small">
          {{ $t('generic.description') }}
        </labeled-input>
      </vk-grid>

      <!-- Resources -->
      <div class="uk-margin-bottom">
        <label class="uk-form-label">{{ $t('generic.resources') }}</label>
        <Resources
          @update="assignResourcesToTask"
          :value="model.resources"
          :show-resources="true"
          resource-type="task"
          position="top-left"
          :key="model.id"
        />
      </div>

      <!-- Comments -->
      <Comments
        :taskId="model.id"
      />
  </Detail>
</template>

<script>
  import ConfirmModal from '../../components/global/modals/ConfirmModal'
  import LabeledInput from '../../components/global/inputs/LabeledInput'
  import VkIconButton from '../../components/global/vuikit/VkIconButton'
  import Resources from '../../components/projects/Resources'
  import Detail from '../../components/layout/details/Detail'
  import Comments from '../../components/projects/Comments'
  import DetailMixin from '../../mixins/detail'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        projects: 'project/projects'
      }),
      projectValues() {
        return [{ id: null, name: this.$t('projects.no_project') }].concat(this.projects)
      }
    },
    data() {
      return {
        status: [
          { id: 'open', name: this.$t('projects.task_status_open') },
          { id: 'waiting', name: this.$t('projects.task_status_waiting') },
          { id: 'in_progress', name: this.$t('projects.task_status_in_progress') },
          { id: 'waiting_for_response', name: this.$t('projects.task_status_waiting_for_response') },
          { id: 'testing', name: this.$t('projects.task_status_testing') },
          { id: 'finished', name: this.$t('projects.task_status_finished') },
        ]
      }
    },
    created() {
      this.setup(
        'taskId',
        'project/taskById',
        'projects.task_created',
      'projects.task_updated',
      'projects.task_deleted')

      // Predefine default model
      if (!this.model.status) {
        this.model.status = 'open'
      }

      if ((this.model && !this.model.project_id)
        && this.$route.query[this.routeParam]
        && this.routeParam === 'taskId') {
        this.model.project_id = this.$route.params.projectId
      }

      this.loading = true
      this.$store.dispatch('project/loadProjects', {
        appId: this.$route.params.appId
      }).finally(() => this.loading = false)
    },
    methods: {
      submit() {
        this.saveModel('project/saveTask').then(() => {
          if (this.isCreateMode) {
            if (this.$route.params[this.routeParam]) {
              return this.redirect('/tasks/' + this.model.id)
            }
            this.redirect('/projects/' + this.model.project_id + '/view?taskId=' + this.model.id)
          }

          return this.$store.dispatch('project/loadProjects', {
            appId: this.$route.params.appId
          })
        })
      },
      deleteTask() {
        this.deleteModel('project/deleteTask').then((res) => {
          if (res && res.success) {
            if (this.$route.params[this.routeParam]) {
              return this.redirect('/tasks')
            }
            return this.redirect('/projects/' + this.model.project_id + '/view')
          }
        })
      },
      /**
       * Returns updated array of values for a specific type (server, domain, page, provider, customer)
       * @param $event
       */
      assignResourcesToTask($event) {
        if (!this.model.resources) {
          this.model.resources = []
        }

        // Transform to array - if it is not
        if (!Array.isArray($event.value)) {
          $event.value = [$event.value]
        }

        this.model.resources = this.model.resources.filter(resource => resource.type !== $event.type)
        $event.value.forEach(value => {
          this.model.resources.push({
            type: $event.type,
            value: value
          })
        })
      }
    },
    mixins: [
      DetailMixin
    ],
    components: {
      ConfirmModal,
      Detail,
      Comments,
      Resources,
      LabeledInput,
      VkIconButton,
    }
  }
</script>