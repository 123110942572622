import base from './base'

export default {
  /**
   * Throttles the api call
   * @param apiCall
   * @param lastTimeCalled
   * @param apiRefreshThreshold
   */
  throttle(apiCall, lastTimeCalled, apiRefreshThreshold = 60) {
    let now = Date.now()
  },
  /**
   * Logins the user in
   * @param email
   * @param password
   * @returns {*}
   */
  login(email, password) {
    return base.post('/auth/login', {
      email,
      password
    })
  },
  /**
   * Signs off the given user based on token
   * @returns {*}
   */
  logout() {
    return base.post('/auth/logout')
  },
  /**
   * Reset the password for the given email
   * @param email
   */
  reset(email) {
    return base.post('/auth/forgot', {
      email
    })
  },
  /**
   * Signs up a new user and app
   * @param lastname
   * @param firstname
   * @param email
   * @param password
   * @param password_confirm
   */
  signup(lastname, firstname, email, password, password_confirm) {
    return base.post('/auth/signup', {
      email,
      password,
      lastname,
      firstname,
      password_confirm,
    })
  },
  /**
   * Updates the authenticated user by given parameters
   * @param lastname
   * @param firstname
   * @param password
   * @param password_confirm
   */
  updateUser(lastname, firstname, password, password_confirm) {
    return base.post('/users/update', {
      lastname,
      firstname,
      password,
      password_confirm
    })
  },

  /**
   * Creates a new user
   * @param appId
   * @param email
   * @param lastname
   * @param firstname
   * @returns {*}
   */
  createUser(appId, email, lastname, firstname) {
    return base.post('/' + appId + '/users', {
      email,
      lastname,
      firstname,
    })
  },

  /**
   * Finds all user which are assigned to this app
   * @param appId
   * @returns {*}
   */
  findUsers(appId) {
    return base.get('/' + appId + '/users')
  },

  /**
   * Deletes the user from the system
   * @param appId
   * @param id
   * @returns {*}
   */
  deleteUser(appId, id) {
    return base.post('/' + appId + '/users/' + id + '/remove')
  },

  /**
   * Creates a new customer
   * @param appId
   * @param name
   * @param phone
   * @param comment
   * @returns {*}
   */
  createCustomer(appId, name, phone, comment) {
    return base.post('/' + appId + '/customers', {
      name,
      phone,
      comment,
    })
  },

  /**
   * Updates an existing customer
   * @param appId
   * @param id
   * @param name
   * @param phone
   * @param comment
   * @returns {*}
   */
  updateCustomer(appId, id, name, phone, comment) {
    return base.post('/' + appId + '/customers/' + id, {
      name,
      phone,
      comment,
    })
  },

  /**
   * Retrieves all customers by given appId
   * @param appId
   * @returns {*}
   */
  findCustomers(appId) {
    return base.get('/' + appId + '/customers')
  },

  /**
   * Deletes the customer by given appId and id
   * @param appId
   * @param id
   * @returns {*}
   */
  deleteCustomer(appId, id) {
    return base.post('/' + appId + ' /customers/' + id + '/remove')
  },

  /**
   * Creates a new provider
   * @param appId
   * @param name
   * @param url
   * @param comment
   * @returns {*}
   */
  createProvider(appId, name, url, comment) {
    return base.post('/' + appId + '/providers', {
      name,
      url,
      comment
    })
  },

  /**
   * Updates an existing provider
   * @param appId
   * @param id
   * @param name
   * @param url
   * @param comment
   * @returns {*}
   */
  updateProvider(appId, id, name, url, comment) {
    return base.post('/' + appId + '/providers/' + id, {
      name,
      url,
      comment
    })
  },

  /**
   * Removes an existing provider
   * @param appId
   * @param id
   * @returns {*}
   */
  deleteProvider(appId, id) {
    return base.post('/' + appId + '/providers/' + id + '/remove')
  },

  /**
   * Retrieves all providers
   * @param appId
   * @returns {*}
   */
  findProviders(appId) {
    return base.get('/' + appId + '/providers')
  },

  /**
   * Retrieves all domains
   * @param appId
   * @returns {*}
   */
  findDomains(appId) {
    return base.get('/' + appId + '/domains')
  },

  /**
   * Creates a new domain
   * @param appId
   * @param name
   * @param customer_id
   * @param provider_id
   * @param comment
   * @returns {*}
   */
  createDomain(appId, name, customer_id, provider_id, comment) {
    return base.post('/' + appId + '/domains', {
      name,
      comment,
      customer_id,
      provider_id,
    })
  },

  /**
   * Updates an existing domain
   * @param appId
   * @param id
   * @param name
   * @param customer_id
   * @param provider_id
   * @param comment
   * @returns {*}
   */
  updateDomain(appId, id, name, customer_id, provider_id, comment) {
    return base.post('/' + appId + '/domains/' + id, {
      name,
      comment,
      customer_id,
      provider_id,
    })
  },

  /**
   * Removes a existing domain
   * @param appId
   * @param id
   * @returns {*}
   */
  deleteDomain(appId, id) {
    return base.post('/' + appId + '/domains/' + id + '/remove')
  },

  /**
   *
   * @param appId
   * @param name
   * @param hostname
   * @param customer_id
   * @param provider_id
   * @param ipv4
   * @param ipv6
   * @param comment
   * @returns {*}
   */
  createServer(appId, name, hostname, customer_id, provider_id, ipv4, ipv6, comment) {
    return base.post('/' + appId + '/servers', {
      name,
      hostname,
      customer_id,
      provider_id,
      ipv4,
      ipv6,
      comment
    })
  },

  /**
   *
   * @param appId
   * @param id
   * @param name
   * @param hostname
   * @param customer_id
   * @param provider_id
   * @param ipv4
   * @param ipv6
   * @param comment
   * @returns {*}
   */
  updateServer(appId, id, name, hostname, customer_id, provider_id, ipv4, ipv6, comment) {
    return base.post('/' + appId + '/servers/' + id, {
      name,
      hostname,
      customer_id,
      provider_id,
      ipv4,
      ipv6,
      comment
    })
  },

  /**
   * Retrieves all saved servers
   * @param appId
   */
  findServers(appId) {
    return base.get('/' + appId + '/servers')
  },
  /**
   * Removes an existing server
   * @param appId
   * @param id
   * @returns {*}
   */
  deleteServer(appId, id) {
    return base.post('/' + appId + '/servers/' + id + '/remove')
  },

  /**
   * Removes an existing page
   * @param appId
   * @param id
   * @returns {*}
   */
  deletePage(appId, id) {
    return base.post('/' + appId + '/pages/' + id + '/remove')
  },

  /**
   * Retrieves all pages assigned to this app
   * @param appId
   */
  findPages(appId) {
    return base.get('/' + appId + '/pages')
  },

  /**
   * Creates a new page
   * @param appId
   * @param name
   * @param domain_id
   * @param server_id
   * @param customer_id
   * @param provider_id
   * @param url
   * @param tag
   * @param monitoring
   * @param comment
   * @returns {*}
   */
  createPage(appId, name, domain_id, server_id, customer_id, provider_id, url, tag, monitoring, comment) {
    return base.post('/' + appId + '/pages', {
      name,
      domain_id,
      server_id,
      customer_id,
      provider_id,
      url,
      tag,
      monitoring,
      comment
    })
  },

  /**
   * Updates an existing page
   * @param appId
   * @param id
   * @param name
   * @param domain_id
   * @param server_id
   * @param customer_id
   * @param provider_id
   * @param url
   * @param tag
   * @param monitoring
   * @param comment
   * @returns {*}
   */
  updatePage(appId, id, name, domain_id, server_id, customer_id, provider_id, url, tag, monitoring, comment) {
    return base.post('/' + appId + '/pages/' + id, {
      name,
      domain_id,
      server_id,
      customer_id,
      provider_id,
      url,
      tag,
      monitoring,
      comment
    })
  },

  /**
   * Performs a search for an app instance
   * @param appId
   * @param keyword
   */
  search(appId, keyword) {
    return base.get('/' + appId + '/search?keyword=' + keyword)
  },

  /**
   * Creates a new task
   * @param appId
   * @param name
   * @param status
   * @param project_id
   * @param comment
   * @param resources
   */
  createTask(appId, name, status, project_id, comment, resources) {
    return base.post('/' + appId + '/tasks', {
      name,
      status,
      project_id,
      comment,
      resources
    })
  },

  /**
   * Updates an existing task
   * @param appId
   * @param id
   * @param name
   * @param status
   * @param project_id
   * @param comment
   * @param resources
   * @returns {*}
   */
  updateTask(appId, id, name, status, project_id, comment, resources) {
    return base.post('/' + appId + '/tasks/' + id, {
      name,
      status,
      project_id,
      comment,
      resources
    })
  },

  /**
   * Removes the existing task
   * @param appId
   * @param id
   * @returns {*}
   */
  deleteTask(appId, id) {
    return base.post('/' + appId + '/tasks/' + id + '/remove')
  },

  /**
   * Retrieves all tasks
   * @param appId
   */
  findTasks(appId) {
    return base.get('/' + appId + '/tasks')
  },

  /**
   * Adds a comment to a task
   * @param appId
   * @param reference_id
   * @param reference_type
   * @param content
   * @returns {*}
   */
  comment(appId, reference_id, reference_type, content) {
    return base.post('/' + appId + '/comments', {
      content,
      reference_id,
      reference_type,
    })
  },

  /**
   * Removes an existing comment
   * @param appId
   * @param id
   */
  deleteComment(appId, id) {
    return base.post('/' + appId + '/comments/' + id + '/remove')
  },

  /**
   * Creates a new project
   * @param appId
   * @param name
   * @param comment
   */
  createProject(appId, name, comment) {
    return base.post('/' + appId + '/projects', {
      name,
      comment,
    })
  },

  /**
   * Updates a new project
   * @param appId
   * @param name
   * @param comment
   */
  updateProject(appId, name, comment) {
    return base.post('/' + appId + '/projects', {
      name,
      comment
    })
  },

  /**
   * Retrieves all projects which are assigned to this app
   * @param appId
   */
  findProjects(appId) {
    return base.get('/' + appId + '/projects')
  },

  /**
   * Assigns resources to a project
   * @param appId
   * @param id - projectId
   * @param resources
   */
  assignResources(appId, id, resources) {
    return base.post('/' + appId + '/projects/' + id + '/assign', {
      resources
    })
  },

  /**
   * Removes an project with all assignements
   * @param appId
   * @param id
   */
  deleteProject(appId, id) {
    return base.post('/' + appId + '/projects/' + id + '/remove')
  },

  /**
   * Assigns a domain to an object
   * @param appId
   * @param id
   * @param reference_type
   * @param reference_ids
   */
  assignToCustomer(appId, id, reference_type, reference_ids) {
    return base.post('/' + appId + '/customers/' + id + '/assign', {
      reference_type,
      reference_ids
    })
  },

  /**
   * Assigns objects to providers
   * @param appId
   * @param id
   * @param reference_type
   * @param reference_ids
   * @returns {*}
   */
  assignToProvider(appId, id, reference_type, reference_ids) {
    return base.post('/' + appId + '/providers/' + id + '/assign', {
      reference_type,
      reference_ids
    })
  },

  /**
   * Assigns objects to domains
   * @param appId
   * @param id
   * @param reference_type
   * @param reference_ids
   * @returns {*}
   */
  assignToDomain(appId, id, reference_type, reference_ids) {
    return base.post('/' + appId + '/domains/' + id + '/assign', {
      reference_type,
      reference_ids
    })
  },

  /**
   * Assigns objects to domains
   * @param appId
   * @param id
   * @param reference_type
   * @param reference_ids
   * @returns {*}
   */
  assignToServer(appId, id, reference_type, reference_ids) {
    return base.post('/' + appId + '/servers/' + id + '/assign', {
      reference_type,
      reference_ids
    })
  },
}