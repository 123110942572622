export default {
  'projects': [
    {id: 'name', label: 'Name'},
    {id: 'comment', label: 'Notiz'}
  ],
  'tasks': [
    { id: 'name', label: 'Name' },
    { id: 'status', label: 'Status', filter: 'taskStatus' },
  ],
  'customers': [
    {id: 'name', label: 'Name'},
    {id: 'phone', label: 'Telefon'}
  ],
  'providers': [
    {id: 'name', label: 'Name'},
    {id: 'url', label: 'URL'}
  ],
  'domains': [
    { id: 'name', label: 'Name' },
  ],
  'servers': [
    { id: 'name', label: 'Name' },
    { id: 'hostname', label: 'Hostname' },
    { id: 'ipv4', label: 'IPV4' },
    { id: 'ipv6', label: 'IPV6' }
  ],
  'pages': [
    { id: 'name', label: 'Seite' },
    { id: 'url', label: 'URL' },
    { id: 'status', label: 'Status', },
    { id: 'tag', label: 'Tags', class: 'uk-label' }
  ]
}