<template></template>

<script>
  export default {
    created() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/' + this.$i18n.locale + '/auth/login')
      })
    }
  }
</script>