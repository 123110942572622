<template>
  <div>
    <label class="uk-form-label">
      <slot />
    </label>
    <template v-if="type !== 'select' && type !== 'textarea'">
      <input
        :type="type"
        :value="value"
        :required="required"
        :disabled="disabled"
        class="uk-input uk-form-controls"
        @input="$emit('input', $event.target.value)"
      />
    </template>
    <!-- Select -->
    <template v-else-if="type === 'select'">
      <select
        :value="value"
        class="uk-select"
        @input="$emit('input', $event.target.value)">
        <option
          :value="val.id"
          v-for="val in values">
          {{ val.name }}
        </option>
      </select>
    </template>
    <!-- Textarea -->
    <template v-else-if="type === 'textarea'">
      <textarea
        rows="5"
        :value="value"
        :disabled="disabled"
        class="uk-textarea uk-form-controls"
        @input="$emit('input', $event.target.value)"
      />
    </template>
  </div>
</template>

<script>
  export default {
    props: [
      'type',
      'value',
      'values',
      'required',
      'disabled',
    ]
  }
</script>