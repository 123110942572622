<template>
  <Table
    :config="config"
    :data="foreignValues"
    @row-selected="selectRow">
    <template slot="controls">
      <IconDropdown
        icon="plus"
        @update="assign"
        position="bottom-left"
        :value="assignedDomains"
        :values="transformedDomains">
        <vk-icon-button
          :tooltip="$t('web.append_domain')"
          @click.prevent.stop=""
          type="button"
          ratio="0.85"
          icon="plus"
        />

        <vk-button
          slot="button"
          type="primary"
          class="uk-width-1-1@s"
          @click.stop="$router.push(createQueryParamLink('new'))">
          {{ $t('web.new_domain') }}
        </vk-button>
      </IconDropdown>
    </template>
  </Table>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Table from '../../../global/Table'
  import QueryParamMixin from '../../../../mixins/query-param'
  import IconDropdown from '../../../../components/projects/IconDropdown'
  import VkIconButton from '../../../../components/global/vuikit/VkIconButton'

  export default {
    computed: {
      ...mapGetters({
        domains: 'web/domains'
      }),
      transformedDomains() {
        return (this.domains || []).map(domain => {
          return {
            id: domain.id,
            name: domain.name
          }
        })
      },
      assignedDomains() {
        if (this.$route.params.customerId) {
          return this.domains
          .filter(domain => domain.customer_id === this.$route.params.customerId)
          .map(domain => domain.id)
        }

        if (this.$route.params.providerId) {
          return this.domains
          .filter(domain => domain.provider_id === this.$route.params.providerId)
          .map(domain => domain.id)
        }
      }
    },
    data() {
      return {
        config: this.createTableConfig('web.no_domains_found', 'domains')
      }
    },
    methods: {
      assign($event) {
        let promise = null
        let payload = {
          reference_ids: $event,
          reference_type: 'domain',
          appId: this.$route.params.appId,
        }

        if (this.$route.params.customerId) {
          payload.id = this.$route.params.customerId
          promise =this.$store.dispatch('customer/assignToCustomer', payload)
        }

        if (this.$route.params.providerId) {
          payload.id = this.$route.params.providerId
          promise = this.$store.dispatch('web/assignToProvider', payload)
        }

        if (promise) {
          /** @var Promise */
          promise.then(() => this.$store.dispatch('web/loadDomains', payload))
        }
      }
    },
    mixins: [
      QueryParamMixin
    ],
    components: {
      IconDropdown,
      Table,
      VkIconButton
    }
  }
</script>