<template>
  <vk-card :class="{ 'uk-card-small': getConfigParam('slim') }">
    <vk-card-title
      class="uk-flex uk-flex-between"
      v-if="getConfigParam('title') || getConfigParam('search') || $slots.controls">
      <div class="table-header">
        <div
          v-if="getConfigParam('title')"
          class="table-header-title">
          {{ getConfigParam('title') }}
        </div>
        <slot name="controls" />
      </div>
      <div
        class="search"
        v-if="getConfigParam('search')">
        <input
          v-model="keyword"
          class="uk-input uk-form-small"
          :placeholder="$t('generic.search')"
        />
      </div>
    </vk-card-title>
    <template v-if="paginatedData && paginatedData.length > 0">
      <div class="uk-overflow-auto">
        <vk-table
          hoverable
          row-selectable
          :data="paginatedData"
          :sorted-by.sync="sortedBy"
          class="uk-padding-medium-top"
          :selected-rows.sync="selectedRows"
          :narrowed="getConfigParam('slim')">
          <vk-table-column-sort
            :key="index"
            :cell="header.id"
            :title="header.label"
            v-slot:default="slotProps"
            v-for="(header, index) in getConfigParam('headers')">
            <template v-if="header.filter">{{ useFilter(header.filter, slotProps.cell) }}</template>
            <template v-else>
              <span :class="{}[header.class] = header.class">
                {{ $te(slotProps.cell) ? $t(slotProps.cell) : slotProps.cell }}
              </span>
            </template>
          </vk-table-column-sort>
        </vk-table>
      </div>
      <vk-pagination
        align="right"
        :page.sync="page"
        class="pagination"
        :per-page="maxPageSize"
        :total="searchAndSortData.length"
        v-if="getConfigParam('pagination')">
        <vk-pagination-page-prev
          @click.prevent="prev"
        />
        <vk-pagination-pages />
        <vk-pagination-page-next
          @click.prevent="next"
        />
      </vk-pagination>
    </template>
    <div class="uk-flex uk-flex-center uk-margin-large" v-else>
      {{ getConfigParam('emptyMessage') }}
    </div>
  </vk-card>
</template>

<script>
  import Vue from 'vue'

  export default {
    props: [
      'data',
      'title',
      'config',
    ],
    computed: {
      maxPageSize() {
        return this.getConfigParam('pageSize') || 20
      },
      searchAndSortData() {
        let data = this.data
        if (data) {
          if (this.keyword) {
            data = data.filter(item => {
              return Object
                .keys(item)
                .filter(item => item !== 'id')
                .filter(property => item[property]
                    && item[property].toString().toLowerCase().indexOf(this.keyword.toLowerCase()) > - 1)
                .length > 0
            })
          }

          let property = Object.keys(this.sortedBy)[0]
          let direction = this.sortedBy[property]
          if (property && direction) {
            data = data.sort((i1, i2) => {
              return direction === 'asc'
                  ? (i1[property] > i2[property] ? 1 : -1)
                  : (i1[property] < i2[property] ? 1 : -1)
            })
          }
        }
        return data
      },
      paginatedData() {
        let data = this.searchAndSortData
        if (this.pagination) {
          let currentRange = (this.page - 1) * this.maxPageSize
          data = data.slice(currentRange, currentRange + this.maxPageSize)
        }
        return data
      }
    },
    data() {
      return {
        page: 1,
        keyword: null,
        selectedRows: [],
        sortedBy: { [this.getConfigParam('headers')[0].id]: 'asc' }
      }
    },
    methods: {
      prev() {
        if (this.page > 1) {
          this.page--
        }
      },
      next() {
        if (this.page < (this.searchAndSortData / this.maxPageSize)) {
          this.page++
        }
      },
      /**
       * Retrieves the parameter from config.
       * @param param
       * @returns {*}
       */
      getConfigParam(param) {
        if (this.config && this.config[param]) {
          return this.config[param]
        }
      },
      useFilter(filter, data) {
        return this.$te(Vue.filter(filter)(data))
          ? this.$t(Vue.filter(filter)(data))
          : Vue.filter(filter)(data)
      }
    },
    watch: {
      selectedRows: {
        deep: true,
        handler() {
          let selectedData = this.data.find(item => item.id === this.selectedRows[0])
          this.$emit('row-selected', selectedData)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-header {
    display: flex;

    .table-header-title {
      margin-right: 8px;
    }
  }

  .search {
    input {
      min-width: 200px;
      text-indent: 8px;
      border-radius: 14px;
    }
  }

  .pagination {
    user-select: none;
  }
</style>