<template>
  <button
    :type="type"
    class="invisible"
    @click="onClick">
    <vk-icon-button
      v-vk-tooltip="tooltip"
      :icon="icon"
    />
  </button>
</template>

<script>
  export default {
    props: [
      'type',
      'icon',
      'tooltip',
    ],
    methods: {
      onClick($event) {
        if (this.type === 'submit' && this.$el) {
          let formElement = this.$el.closest('form')
          if (formElement) {
            return formElement.submit()
          }
        }
        return this.$emit('click', $event)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .invisible {
    padding: 0;
    border: none;
    background: transparent;
  }
</style>