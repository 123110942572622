<template>
  <div class="task-resources">
    <div class="dropdown-container">
      <IconDropdown
        :key="index"
        :position="position"
        :icon="control.icon"
        :title="control.title"
        :class="control.class"
        :value="getValuesByType(control.id)"
        :values="getDropdownValues(control.id)"
        :selected="selectedResource.id === control.id"
        v-for="(control, index) in controls"
        @click.prevent="selectResourceType(control)"
        @update="$emit('update', {value: $event, type: control.id })"
      />
    </div>
    <div
      class="resources"
      v-if="showResources">
      <label class="uk-form-label uk-margin-small-top">{{ $t('projects.assigned') }} {{ selectedResource.title }}</label>
      <div class="resources-container">
        <template v-if="getValuesByType(selectedResource.id).length > 0">
          <IconButton
            :key="index"
            :selected="true"
            :title="getValue(value)"
            class="uk-margin-small-right"
            @click="viewResource(value)"
            v-for="(value, index) in getValuesByType(selectedResource.id)">
            <vk-icon
              ratio="0.8"
              :icon="selectedResource.icon"
            />
          </IconButton>
        </template>
        <div v-else>---</div>
      </div>
    </div>
  </div>
</template>

<script>
  import IconDropdown from '../../components/projects/IconDropdown'
  import IconButton from '../../components/projects/IconButton'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'showResources',
      'resourceType',
      'position',
      'value'
    ],
    computed: {
      ...mapGetters({
        projectById: 'project/projectById',
        customers: 'customer/customers',
        taskById: 'project/taskById',
        providers: 'web/providers',
        domains: 'web/domains',
        servers: 'web/servers',
        pages: 'web/pages',
        users: 'app/users'
      }),
    },
    data() {
      return {
        controls: [
          {
            id: 'customers',
            icon: 'users',
            title: this.$t('nav.customers'),
            class: 'uk-margin-small-left',
            queryParam: 'customerId'
          },
          {
            id: 'providers',
            icon: 'close',
            title: this.$t('nav.providers'),
            class: 'uk-margin-small-left',
            queryParam: 'providerId'
          },
          {
            id: 'domains',
            icon: 'close',
            title: this.$t('nav.domains'),
            class: 'uk-margin-small-left',
            queryParam: 'domainId'
          },
          {
            id: 'servers',
            icon: 'server',
            title: this.$t('nav.servers'),
            class: 'uk-margin-small-left',
            queryParam: 'serverId'
          },
          {
            id: 'pages',
            icon: 'world',
            title: this.$t('nav.pages'),
            class: 'uk-margin-small-left',
            queryParam: 'pageId'
          },
        ],
        selectedResource: null,
      }
    },
    created() {
      let payload = { appId: this.$route.params.appId }
      Promise.all([
        this.$store.dispatch('project/loadProjects', payload),
        this.$store.dispatch('web/loadProviders', payload),
        this.$store.dispatch('web/loadDomains', payload),
        this.$store.dispatch('web/loadServers', payload),
        this.$store.dispatch('web/loadPages', payload),
        this.$store.dispatch('app/loadUsers', payload),
      ])
      this.selectedResource = this.controls[0]
    },
    methods: {
      getDropdownValues(id) {
        if (id === 'users') {
          return this[id].map(item => {
            return {
              ...item,
              name: item.fullname
            }
          })
        }
        return this[id].filter(item => {
          if (this.resourceType === 'task') {
            let projectId = this.$route.params.projectId
            if (!projectId) {
              let task = this.taskById(this.$route.params.taskId || this.$route.query.taskId)
              if (task) {
                projectId = task.project_id
              }
            }

            let project = this.projectById(projectId)
            if (project && project.resources) {
              return !!project.resources.find(resource => resource.type === id && item.id === resource.value)
            }
          }
          return true
        })
      },
      getValuesByType(id) {
        if (this.value) {
          return this.value
            .filter(item => item.type === id)
            .map(item => item.value)
        }
        return []
      },
      selectResourceType(selectedResource) {
        this.selectedResource = selectedResource
      },
      getValue(id) {
        if (this.selectedResource
          && this[this.selectedResource.id]) {
          let resource = this[this.selectedResource.id].find(item => item.id === id)
          if (resource) {
            return resource.name
          }
        }
      },
      viewResource(resource) {
        if (this.selectedResource && resource) {
          // if this resource component is displayed on project view route
          // we display the resource on the project view 'right' side slot (project layout).
          if (this.$route.params.projectId) {
            return this.$router.push({
              query: {[this.selectedResource.queryParam]: resource}
            })
          }

          return this.$router.push('/' + this.$i18n.locale
            + '/' + this.$route.params.appId
            + '/' + this.selectedResource.id
            + '/' + resource)
        }
      }
    },
    components: {
      IconDropdown,
      IconButton
    }
  }
</script>

<style lang="scss" scoped>
  .task-resources {
    .dropdown-container {
      display: flex;
      flex-wrap: wrap;


      .rounded {
        border-radius: 50%;
      }
    }

    .resources-container {
      display: flex;
      margin-top: 8px;
      margin-left: 10px;
    }
  }
</style>