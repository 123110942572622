<template>
  <NavigationLayout :padding="true">
    <vk-container>
      <vk-grid class="uk-child-width-expand@s">
        <div>
          <vk-card padding="small">
            <h2>{{ $t('nav.dashboard') }}</h2>
          </vk-card>
        </div>
      </vk-grid>
      <vk-grid class="uk-child-width-expand@s">
        <div>
          <div class="actions-headline"></div>
          <div class="actions-buttons">
            <vk-icon-button
              type="button"
              icon="calendar"
              @click="iconClick('/projects/new')"
              :tooltip="tooltip('tooltips.create_project')"
            />
            <vk-icon-button
              type="button"
              icon="check"
              class="uk-margin-small-left"
              @click="iconClick('/tasks/new')"
              :tooltip="tooltip('tooltips.create_task')"
            />
            <vk-icon-button
              type="button"
              icon="users"
              class="uk-margin-small-left"
              @click="iconClick('/customers/new')"
              :tooltip="tooltip('tooltips.create_customer')"
            />
            <vk-icon-button
              type="button"
              icon="close"
              class="uk-margin-small-left"
              @click="iconClick('/providers/new')"
              :tooltip="tooltip('tooltips.create_provider')"
            />
            <vk-icon-button
              type="button"
              icon="close"
              class="uk-margin-small-left"
              @click="iconClick('/domains/new')"
              :tooltip="tooltip('tooltips.create_domain')"
            />
            <vk-icon-button
              type="button"
              icon="server"
              class="uk-margin-small-left"
              @click="iconClick('/servers/new')"
              :tooltip="tooltip('tooltips.create_server')"
            />
            <vk-icon-button
              type="button"
              icon="world"
              class="uk-margin-small-left"
              @click="iconClick('/pages/new')"
              :tooltip="tooltip('tooltips.create_page')"
            />
          </div>
        </div>
      </vk-grid>
      <vk-grid class="uk-child-width-expand@m">
        <div>
          <Table
            :data="tasks"
            :config="createTableConfig(taskTableConfig)"
            @row-selected="onRowClick('tasks', $event)"
          />
        </div>
        <div>
          <Table
            :data="pages"
            :config="createTableConfig(pagesTableConfig)"
            @row-selected="onRowClick('pages', $event)"
          />
        </div>
      </vk-grid>
    </vk-container>
  </NavigationLayout>
</template>

<script>
  import NavigationLayout from '../components/layout/NavigationLayout'
  import VkContainer from '../components/global/vuikit/VkContainer'
  import VkIconButton from '../components/global/vuikit/VkIconButton'
  import TableHelper from '../helpers/table-headers'
  import Table from '../components/global/Table'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        tasks: 'project/tasks',
        pages: 'web/pages'
      })
    },
    data() {
      return {
        loading: false,
        taskTableConfig: {
          id: 'tasks',
          label: 'nav.tasks',
          message: 'projects.no_task_found'
        },
        pagesTableConfig: {
          id: 'pages',
          label: 'nav.pages',
          message: 'web.no_pages_found',
        },
      }
    },
    created() {
      this.loading = true
      let payload = { appId: this.$route.params.appId }
      Promise.all([
        this.$store.dispatch('project/loadTasks', payload),
        this.$store.dispatch('web/loadPages', payload)
      ]).finally(() => this.loading = false)
    },
    methods: {
      onRowClick(section, $event) {
        switch (section) {
          case 'pages':
            this.iconClick('/pages/' + $event.id)
            break
          case 'tasks':
            this.iconClick('/tasks/' + $event.id)
            break
        }
      },
      iconClick(route) {
        this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + route)
      },
      tooltip(translatedText) {
        return {
          title: this.$t(translatedText),
          delay: this.$store.getters['app/tooltipDelay']
        }
      },
      createTableConfig(header) {
        return {
          slim: true,
          search: false,
          pagination: true,
          title: this.$t(header.label),
          headers: TableHelper[header.id],
          emptyMessage: this.$t(header.message),
        }
      }
    },
    components: {
      NavigationLayout,
      VkIconButton,
      VkContainer,
      Table
    }
  }
</script>

<style lang="scss" scoped>
  .actions-headline {
    font-size: 14px;
    font-weight: 500;
  }

  .actions-buttons {
    display: flex;
    //margin-top: 16px;
  }
</style>