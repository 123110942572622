<template>
  <div class="split-layout">
    <div class="split-column">
      <slot />
    </div>
    <div
      class="split-column"
      v-if="displaySecondColumn">
      <component :is="dynamicComponent" />
    </div>
  </div>
</template>

<script>
  export default {
    props: [
        'routeParam',
        'queryComponent',
        'routeComponent',
        'query-param-map',
    ],
    computed: {
      displaySecondColumn() {
        return Object.keys(this.queryParamMap).filter(item => !!this.$route.query[item]).length > 0
            || this.$route.params[this.routeParam]
      },
      isRouteComponent() {
        return this.$route.params[this.routeParam]
            && Object.keys(this.queryParamMap).filter(item => !!this.$route.query[item]).length === 0
      },
      dynamicComponent() {
        let queryParam = Object.keys(this.queryParamMap).filter(item => !!this.$route.query[item])[0]
        return this.isRouteComponent
            ? this.routeComponent
            : this.queryParamMap[queryParam]
      }
    },
  }
</script>

<style lang="scss" scoped>
  .split-layout {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .split-column {
      flex-grow: 0;
      height: 100%;
      margin: 0 6px;
      flex-basis: 70%;
      overflow-y: auto;
    }
  }
</style>