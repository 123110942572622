<template>
  <div class="uk-flex uk-flex-center">
    <div class="form-wrapper">
      <form
        class="uk-form-stacked"
        @submit.prevent="submit">
        <h3>{{ $t('auth.login') }}</h3>
        <labeled-input
          required
          type="email"
          v-model="email"
          class="uk-width-1-1@s">
          {{ $t('generic.email') }}
        </labeled-input>

        <labeled-input
          required
          type="password"
          v-model="password"
          class="uk-width-1-1@s uk-margin-small-top">
          {{ $t('generic.password') }}
        </labeled-input>

        <div class="uk-flex uk-flex-between uk-margin-top">
          <vk-loadable-button
            type="primary"
            :loading="loading"
            html-type="submit">
            {{ $t('auth.login') }}
          </vk-loadable-button>

          <div class="uk-flex uk-flex-column uk-text-right">
            <router-link :to="'/' + $i18n.locale + '/auth/signup'">
              <vk-button type="link">{{ $t('auth.not_registered_yet') }}</vk-button>
            </router-link>

            <router-link :to="'/' + $i18n.locale + '/auth/forgot'">
              <vk-button type="link">{{ $t('auth.forgot') }}</vk-button>
            </router-link>
          </div>
        </div>
      </form>

      <div class="uk-margin-medium uk-flex uk-flex-center">
        <Sitetape />
      </div>
    </div>
  </div>
</template>

<script>
  import Sitetape from '../../components/global/Sitetape'
  import LabeledInput from '../../components/global/inputs/LabeledInput'
  import VkLoadableButton from '../../components/global/vuikit/VkLoadableButton'

  export default {
    data() {
      return {
        email: null,
        password: null,
        loading: false,
      }
    },
    methods: {
      submit() {
        this.loading = true
        this.$store.dispatch('auth/login', {
          password: this.password,
          email: this.email,
        }).then(res => {
          if (res && res.success) {
            // Set window.var to x @ first login
            if (res.data.firstLogin) {
              //window.pixel = true
            }

            this.$toasted.success(this.$t('auth.welcome'))
            this.$router.push('/' + this.$i18n.locale + '/' + res.data.appId)
          }
        }).finally(() => this.loading = false)
      }
    },
    components: {
      VkLoadableButton,
      LabeledInput,
      Sitetape
    }
  }
</script>

<style lang="scss" scoped>
  .form-wrapper {
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    max-width: 560px;
    padding: 0 16px;
    display: flex;
    width: 100%;

    form {
      padding: 24px;
      width: calc(100% - 48px);
      background: $color-secondary;
    }
  }

  .uk-flex {
    .uk-flex-column {
      flex-direction: column;
    }
  }
</style>