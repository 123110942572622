<template>
  <NavigationLayout :padding="true">
    <SplitLayout
      :route-param="routeParam"
      :query-param-map="queryParamMap"
      :route-component="components.routeComponent">
      <Table
        :data="domains"
        :config="config"
        @row-selected="rowSelected">
        <template slot="controls">
          <vk-icon-button
            :href="createNewObjectLink"
            ratio="0.85"
            icon="plus"
          />
        </template>
      </Table>
    </SplitLayout>
  </NavigationLayout>
</template>

<script>
import NavigationLayout from '../../components/layout/NavigationLayout'
import SplitLayout from '../../components/layout/SplitLayout'
import SplitViewMixin from '../../mixins/split-view'
import Table from '../../components/global/Table'
import Domain from '../../views/web/Domain'
import Page from '../../views/web/Page'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      domains: 'web/domains'
    })
  },
  data() {
    return {
      config: null,
    }
  },
  created() {
    this.setup('domains', 'domainId', Domain, {
      pageId: Page
    })
    this.config = this.createTableConfig('nav.domains', 'web.no_domains_found')

    this.loading = true
    this.$store.dispatch('web/loadDomains', {
      appId: this.$route.params.appId
    }).finally(() => this.loading = false)
  },
  mixins: [
    SplitViewMixin
  ],
  components: {
    Table,
    SplitLayout,
    NavigationLayout
  }
}
</script>