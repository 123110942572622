<template>
  <Detail
    :title="title('web.new_domain')"
    @submit="submit">

    <template slot="header-controls">
      <vk-icon-button
        icon="check"
        type="submit"
        :tooltip="tooltip('generic.save')"
      />
      <ConfirmModal
        :title="$t('web.confirm_delete_domain_title')"
        :message="$t('web.confirm_delete_domain_message')"
        @confirm="deleteDomain">
        <vk-icon-button
          icon="trash"
          type="button"
          v-if="!isCreateMode"
          class="uk-margin-small-left"
          :tooltip="tooltip('generic.delete')"
        />
      </ConfirmModal>
    </template>

    <vk-tabs>
      <vk-tabs-item :title="$t('generic.general')">
        <vk-grid class="uk-margin-top">
          <labeled-input
            required
            v-model="model.name"
            class="uk-width-1-1@s">
            {{ $t('generic.name') }}
          </labeled-input>

          <labeled-input
            type="select"
            :values="customerValues"
            v-model="model.customer_id"
            class="uk-width-1-2@s uk-margin-small">
            {{ $t('generic.customer') }}
          </labeled-input>

          <labeled-input
            type="select"
            :values="providerValues"
            v-model="model.provider_id"
            class="uk-width-1-2@s uk-margin-small">
            {{ $t('generic.provider') }}
          </labeled-input>

          <labeled-input
              type="textarea"
              v-model="model.comment"
              class="uk-width-1-1@s uk-margin-small">
            {{ $t('generic.note') }}
          </labeled-input>
        </vk-grid>
      </vk-tabs-item>

      <!-- Seiten -->
      <vk-tabs-item
        :icon="tabIcon"
        :title="$t('nav.pages')"
        :disabled="isCreateMode">
        <Pages
          query-param="pageId"
          :foreign-values="pageValues"
        />
      </vk-tabs-item>
    </vk-tabs>
  </Detail>
</template>

<script>
  import {mapGetters} from 'vuex'
  import DetailMixin from '../../mixins/detail'
  import Detail from '../../components/layout/details/Detail'
  import Pages from '../../components/layout/details/references/Pages'
  import LabeledInput from '../../components/global/inputs/LabeledInput'
  import VkIconButton from '../../components/global/vuikit/VkIconButton'
  import ConfirmModal from '../../components/global/modals/ConfirmModal'

  export default {
    computed: {
      ...mapGetters({
        customers: 'customer/customers',
        providers: 'web/providers',
        pages: 'web/pages',
      }),
      customerValues() {
        return [{id: null, name: this.$t('customers.no_customer')}].concat(this.customers)
      },
      providerValues() {
        return [{id: null, name: this.$t('web.no_provider')}].concat(this.providers)
      },
      pageValues() {
        return this.pages.filter(page => page.domain_id === this.$route.params[this.routeParam]
          || page.domain_id === this.$route.query[this.routeParam])
      }
    },
    created() {
      this.setup(
        'domainId',
        'web/domainById',
      'web.domain_created',
      'web.domain_updated',
      'web.domain_deleted')

      // Prefill forms
      if (this.isCreateMode) {
        if (this.$route.params.providerId) {
          this.model.provider_id = this.$route.params.providerId
        }
        if (this.$route.params.customerId) {
          this.model.customer_id = this.$route.params.customerId
        }
      }

      // Load referencing models
      this.prefetchData([
        'customer/loadCustomers',
        'web/loadProviders',
        'web/loadPages'
      ])
    },
    methods: {
      submit() {
        this.saveModel('web/saveDomain').then(() => {
          if (this.isCreateMode) {
            if (this.isViewCalledByQueryParam) {
              return this.$router.push({query: {[this.routeParam]: this.model.id}})
            }
            this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/domains/' + this.model.id)
          }
        })
      },
      deleteDomain() {
        return this.deleteModel('web/deleteDomain').then((res) => {
          if (res && res.success) {
            this.$router.push('/' + this.$i18n.locale + '/' + this.$route.params.appId + '/domains')
          }
        })
      }
    },
    mixins: [
      DetailMixin
    ],
    components: {
      ConfirmModal,
      VkIconButton,
      LabeledInput,
      Detail,
      Pages,
    }
  }
</script>