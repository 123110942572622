<template>
  <NavigationLayout :padding="true">
    <SplitLayout
      :route-param="routeParam"
      :query-param-map="queryParamMap"
      :route-component="components.routeComponent">
      <Table
        :config="config"
        :data="projects"
        @row-selected="showProjectView">
        <template slot="controls">
          <vk-icon-button
            :href="createNewObjectLink"
            ratio="0.85"
            icon="plus"
          />
        </template>
      </Table>
    </SplitLayout>
  </NavigationLayout>
</template>

<script>
  import NavigationLayout from '../../components/layout/NavigationLayout'
  import VkContainer from '../../components/global/vuikit/VkContainer'
  import SplitLayout from '../../components/layout/SplitLayout'
  import SplitViewMixin from '../../mixins/split-view'
  import Project from '../../views/projects/Project'
  import Table from '../../components/global/Table'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        projects: 'project/projects'
      })
    },
    data() {
      return {
        config: null,
      }
    },
    created() {
      this.setup('projects', 'projectId', Project, 'projectId')
      this.config = this.createTableConfig('nav.projects', 'projects.no_project_found')

      // Load Projects
      this.loading = true
      this.$store.dispatch('project/loadProjects', {
        appId: this.$route.params.appId
      }).finally(() => this.loading = false)
    },
    methods: {
      async showProjectView($evt) {
        let newRoute = this.sectionBaseURL + '/' + $evt.id + '/view'
        if (newRoute !== this.$route.path) {
          await this.$router.push(newRoute)
        }
      }
    },
    mixins: [
      SplitViewMixin
    ],
    components: {
      VkContainer,
      Table,
      SplitLayout,
      NavigationLayout
    }
  }
</script>